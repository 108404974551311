// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-confirm-js": () => import("./../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-docs-index-js": () => import("./../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-v-1-01-frontend-integration-01-connect-to-uniswap-md": () => import("./../src/pages/docs/v1/01-frontend-integration/01-connect-to-uniswap.md" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-01-connect-to-uniswap-md" */),
  "component---src-pages-docs-v-1-01-frontend-integration-02-pool-liquidity-md": () => import("./../src/pages/docs/v1/01-frontend-integration/02-pool-liquidity.md" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-02-pool-liquidity-md" */),
  "component---src-pages-docs-v-1-01-frontend-integration-03-trade-tokens-md": () => import("./../src/pages/docs/v1/01-frontend-integration/03-trade-tokens.md" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-03-trade-tokens-md" */),
  "component---src-pages-docs-v-1-01-frontend-integration-04-custom-linking-md": () => import("./../src/pages/docs/v1/01-frontend-integration/04-custom-linking.md" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-04-custom-linking-md" */),
  "component---src-pages-docs-v-1-01-frontend-integration-05-iframe-integration-md": () => import("./../src/pages/docs/v1/01-frontend-integration/05-iframe-integration.md" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-05-iframe-integration-md" */),
  "component---src-pages-docs-v-1-01-frontend-integration-06-token-listing-md": () => import("./../src/pages/docs/v1/01-frontend-integration/06-token-listing.md" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-06-token-listing-md" */),
  "component---src-pages-docs-v-1-01-frontend-integration-index-js": () => import("./../src/pages/docs/v1/01-frontend-integration/index.js" /* webpackChunkName: "component---src-pages-docs-v-1-01-frontend-integration-index-js" */),
  "component---src-pages-docs-v-1-02-sdk-01-getting-started-md": () => import("./../src/pages/docs/v1/02-SDK/01-getting-started.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-01-getting-started-md" */),
  "component---src-pages-docs-v-1-02-sdk-02-data-md": () => import("./../src/pages/docs/v1/02-SDK/02-data.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-02-data-md" */),
  "component---src-pages-docs-v-1-02-sdk-03-computation-md": () => import("./../src/pages/docs/v1/02-SDK/03-computation.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-03-computation-md" */),
  "component---src-pages-docs-v-1-02-sdk-04-format-md": () => import("./../src/pages/docs/v1/02-SDK/04-format.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-04-format-md" */),
  "component---src-pages-docs-v-1-02-sdk-05-orchestration-md": () => import("./../src/pages/docs/v1/02-SDK/05-orchestration.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-05-orchestration-md" */),
  "component---src-pages-docs-v-1-02-sdk-06-transact-md": () => import("./../src/pages/docs/v1/02-SDK/06-transact.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-06-transact-md" */),
  "component---src-pages-docs-v-1-02-sdk-07-constants-md": () => import("./../src/pages/docs/v1/02-SDK/07-constants.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-07-constants-md" */),
  "component---src-pages-docs-v-1-02-sdk-08-types-md": () => import("./../src/pages/docs/v1/02-SDK/08-types.md" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-08-types-md" */),
  "component---src-pages-docs-v-1-02-sdk-index-js": () => import("./../src/pages/docs/v1/02-SDK/index.js" /* webpackChunkName: "component---src-pages-docs-v-1-02-sdk-index-js" */),
  "component---src-pages-docs-v-1-03-smart-contracts-01-factory-md": () => import("./../src/pages/docs/v1/03-smart-contracts/01-factory.md" /* webpackChunkName: "component---src-pages-docs-v-1-03-smart-contracts-01-factory-md" */),
  "component---src-pages-docs-v-1-03-smart-contracts-02-exchange-md": () => import("./../src/pages/docs/v1/03-smart-contracts/02-exchange.md" /* webpackChunkName: "component---src-pages-docs-v-1-03-smart-contracts-02-exchange-md" */),
  "component---src-pages-docs-v-1-03-smart-contracts-03-interfaces-md": () => import("./../src/pages/docs/v1/03-smart-contracts/03-interfaces.md" /* webpackChunkName: "component---src-pages-docs-v-1-03-smart-contracts-03-interfaces-md" */),
  "component---src-pages-docs-v-1-03-smart-contracts-index-js": () => import("./../src/pages/docs/v1/03-smart-contracts/index.js" /* webpackChunkName: "component---src-pages-docs-v-1-03-smart-contracts-index-js" */),
  "component---src-pages-docs-v-1-index-md": () => import("./../src/pages/docs/v1/index.md" /* webpackChunkName: "component---src-pages-docs-v-1-index-md" */),
  "component---src-pages-docs-v-2-01-protocol-overview-01-how-vexchange-works-md": () => import("./../src/pages/docs/v2/01-protocol-overview/01-how-vexchange-works.md" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-01-how-vexchange-works-md" */),
  "component---src-pages-docs-v-2-01-protocol-overview-02-vexchange-uniswap-delta-md": () => import("./../src/pages/docs/v2/01-protocol-overview/02-vexchange-uniswap-delta.md" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-02-vexchange-uniswap-delta-md" */),
  "component---src-pages-docs-v-2-01-protocol-overview-03-ecosystem-participants-md": () => import("./../src/pages/docs/v2/01-protocol-overview/03-ecosystem-participants.md" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-03-ecosystem-participants-md" */),
  "component---src-pages-docs-v-2-01-protocol-overview-04-smart-contracts-md": () => import("./../src/pages/docs/v2/01-protocol-overview/04-smart-contracts.md" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-04-smart-contracts-md" */),
  "component---src-pages-docs-v-2-01-protocol-overview-05-glossary-md": () => import("./../src/pages/docs/v2/01-protocol-overview/05-glossary.md" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-05-glossary-md" */),
  "component---src-pages-docs-v-2-01-protocol-overview-index-js": () => import("./../src/pages/docs/v2/01-protocol-overview/index.js" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-index-js" */),
  "component---src-pages-docs-v-2-01-protocol-overview-reference-advanced-topics-js": () => import("./../src/pages/docs/v2/01-protocol-overview/reference/Advanced-topics.js" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-reference-advanced-topics-js" */),
  "component---src-pages-docs-v-2-01-protocol-overview-reference-core-concepts-js": () => import("./../src/pages/docs/v2/01-protocol-overview/reference/Core-concepts.js" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-reference-core-concepts-js" */),
  "component---src-pages-docs-v-2-01-protocol-overview-reference-whitepaper-js": () => import("./../src/pages/docs/v2/01-protocol-overview/reference/Whitepaper.js" /* webpackChunkName: "component---src-pages-docs-v-2-01-protocol-overview-reference-whitepaper-js" */),
  "component---src-pages-docs-v-2-02-core-concepts-01-swaps-md": () => import("./../src/pages/docs/v2/02-core-concepts/01-swaps.md" /* webpackChunkName: "component---src-pages-docs-v-2-02-core-concepts-01-swaps-md" */),
  "component---src-pages-docs-v-2-02-core-concepts-02-pools-md": () => import("./../src/pages/docs/v2/02-core-concepts/02-pools.md" /* webpackChunkName: "component---src-pages-docs-v-2-02-core-concepts-02-pools-md" */),
  "component---src-pages-docs-v-2-02-core-concepts-03-flash-swaps-md": () => import("./../src/pages/docs/v2/02-core-concepts/03-flash-swaps.md" /* webpackChunkName: "component---src-pages-docs-v-2-02-core-concepts-03-flash-swaps-md" */),
  "component---src-pages-docs-v-2-02-core-concepts-04-oracles-md": () => import("./../src/pages/docs/v2/02-core-concepts/04-oracles.md" /* webpackChunkName: "component---src-pages-docs-v-2-02-core-concepts-04-oracles-md" */),
  "component---src-pages-docs-v-2-02-core-concepts-index-js": () => import("./../src/pages/docs/v2/02-core-concepts/index.js" /* webpackChunkName: "component---src-pages-docs-v-2-02-core-concepts-index-js" */),
  "component---src-pages-docs-v-2-02-core-concepts-reference-integration-quick-start-js": () => import("./../src/pages/docs/v2/02-core-concepts/reference/Integration-quick-start.js" /* webpackChunkName: "component---src-pages-docs-v-2-02-core-concepts-reference-integration-quick-start-js" */),
  "component---src-pages-docs-v-2-03-advanced-topics-01-fees-md": () => import("./../src/pages/docs/v2/03-advanced-topics/01-fees.md" /* webpackChunkName: "component---src-pages-docs-v-2-03-advanced-topics-01-fees-md" */),
  "component---src-pages-docs-v-2-03-advanced-topics-02-pricing-md": () => import("./../src/pages/docs/v2/03-advanced-topics/02-pricing.md" /* webpackChunkName: "component---src-pages-docs-v-2-03-advanced-topics-02-pricing-md" */),
  "component---src-pages-docs-v-2-03-advanced-topics-03-understanding-returns-md": () => import("./../src/pages/docs/v2/03-advanced-topics/03-understanding-returns.md" /* webpackChunkName: "component---src-pages-docs-v-2-03-advanced-topics-03-understanding-returns-md" */),
  "component---src-pages-docs-v-2-03-advanced-topics-04-security-md": () => import("./../src/pages/docs/v2/03-advanced-topics/04-security.md" /* webpackChunkName: "component---src-pages-docs-v-2-03-advanced-topics-04-security-md" */),
  "component---src-pages-docs-v-2-03-advanced-topics-05-math-md": () => import("./../src/pages/docs/v2/03-advanced-topics/05-math.md" /* webpackChunkName: "component---src-pages-docs-v-2-03-advanced-topics-05-math-md" */),
  "component---src-pages-docs-v-2-03-advanced-topics-06-research-md": () => import("./../src/pages/docs/v2/03-advanced-topics/06-research.md" /* webpackChunkName: "component---src-pages-docs-v-2-03-advanced-topics-06-research-md" */),
  "component---src-pages-docs-v-2-03-advanced-topics-index-js": () => import("./../src/pages/docs/v2/03-advanced-topics/index.js" /* webpackChunkName: "component---src-pages-docs-v-2-03-advanced-topics-index-js" */),
  "component---src-pages-docs-v-2-04-javascript-sdk-01-quick-start-md": () => import("./../src/pages/docs/v2/04-javascript-SDK/01-quick-start.md" /* webpackChunkName: "component---src-pages-docs-v-2-04-javascript-sdk-01-quick-start-md" */),
  "component---src-pages-docs-v-2-04-javascript-sdk-02-fetching-data-md": () => import("./../src/pages/docs/v2/04-javascript-SDK/02-fetching-data.md" /* webpackChunkName: "component---src-pages-docs-v-2-04-javascript-sdk-02-fetching-data-md" */),
  "component---src-pages-docs-v-2-04-javascript-sdk-03-pricing-md": () => import("./../src/pages/docs/v2/04-javascript-SDK/03-pricing.md" /* webpackChunkName: "component---src-pages-docs-v-2-04-javascript-sdk-03-pricing-md" */),
  "component---src-pages-docs-v-2-04-javascript-sdk-04-trading-md": () => import("./../src/pages/docs/v2/04-javascript-SDK/04-trading.md" /* webpackChunkName: "component---src-pages-docs-v-2-04-javascript-sdk-04-trading-md" */),
  "component---src-pages-docs-v-2-04-javascript-sdk-05-getting-pair-addresses-md": () => import("./../src/pages/docs/v2/04-javascript-SDK/05-getting-pair-addresses.md" /* webpackChunkName: "component---src-pages-docs-v-2-04-javascript-sdk-05-getting-pair-addresses-md" */),
  "component---src-pages-docs-v-2-04-javascript-sdk-index-js": () => import("./../src/pages/docs/v2/04-javascript-SDK/index.js" /* webpackChunkName: "component---src-pages-docs-v-2-04-javascript-sdk-index-js" */),
  "component---src-pages-docs-v-2-05-sdk-01-getting-started-md": () => import("./../src/pages/docs/v2/05-SDK/01-getting-started.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-01-getting-started-md" */),
  "component---src-pages-docs-v-2-05-sdk-02-token-md": () => import("./../src/pages/docs/v2/05-SDK/02-token.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-02-token-md" */),
  "component---src-pages-docs-v-2-05-sdk-03-pair-md": () => import("./../src/pages/docs/v2/05-SDK/03-pair.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-03-pair-md" */),
  "component---src-pages-docs-v-2-05-sdk-04-route-md": () => import("./../src/pages/docs/v2/05-SDK/04-route.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-04-route-md" */),
  "component---src-pages-docs-v-2-05-sdk-05-trade-md": () => import("./../src/pages/docs/v2/05-SDK/05-trade.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-05-trade-md" */),
  "component---src-pages-docs-v-2-05-sdk-06-fractions-md": () => import("./../src/pages/docs/v2/05-SDK/06-fractions.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-06-fractions-md" */),
  "component---src-pages-docs-v-2-05-sdk-07-fetcher-md": () => import("./../src/pages/docs/v2/05-SDK/07-fetcher.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-07-fetcher-md" */),
  "component---src-pages-docs-v-2-05-sdk-08-other-exports-md": () => import("./../src/pages/docs/v2/05-SDK/08-other-exports.md" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-08-other-exports-md" */),
  "component---src-pages-docs-v-2-05-sdk-index-js": () => import("./../src/pages/docs/v2/05-SDK/index.js" /* webpackChunkName: "component---src-pages-docs-v-2-05-sdk-index-js" */),
  "component---src-pages-docs-v-2-06-smart-contracts-01-factory-md": () => import("./../src/pages/docs/v2/06-smart-contracts/01-factory.md" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-01-factory-md" */),
  "component---src-pages-docs-v-2-06-smart-contracts-02-pair-md": () => import("./../src/pages/docs/v2/06-smart-contracts/02-pair.md" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-02-pair-md" */),
  "component---src-pages-docs-v-2-06-smart-contracts-03-pair-vip-180-md": () => import("./../src/pages/docs/v2/06-smart-contracts/03-pair-vip-180.md" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-03-pair-vip-180-md" */),
  "component---src-pages-docs-v-2-06-smart-contracts-04-library-md": () => import("./../src/pages/docs/v2/06-smart-contracts/04-library.md" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-04-library-md" */),
  "component---src-pages-docs-v-2-06-smart-contracts-05-router-01-md": () => import("./../src/pages/docs/v2/06-smart-contracts/05-router01.md" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-05-router-01-md" */),
  "component---src-pages-docs-v-2-06-smart-contracts-06-router-02-md": () => import("./../src/pages/docs/v2/06-smart-contracts/06-router02.md" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-06-router-02-md" */),
  "component---src-pages-docs-v-2-06-smart-contracts-07-common-errors-md": () => import("./../src/pages/docs/v2/06-smart-contracts/07-common-errors.md" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-07-common-errors-md" */),
  "component---src-pages-docs-v-2-06-smart-contracts-index-js": () => import("./../src/pages/docs/v2/06-smart-contracts/index.js" /* webpackChunkName: "component---src-pages-docs-v-2-06-smart-contracts-index-js" */),
  "component---src-pages-docs-v-2-07-governance-01-overview-md": () => import("./../src/pages/docs/v2/07-governance/01-overview.md" /* webpackChunkName: "component---src-pages-docs-v-2-07-governance-01-overview-md" */),
  "component---src-pages-docs-v-2-07-governance-02-process-md": () => import("./../src/pages/docs/v2/07-governance/02-process.md" /* webpackChunkName: "component---src-pages-docs-v-2-07-governance-02-process-md" */),
  "component---src-pages-docs-v-2-07-governance-03-guide-to-voting-md": () => import("./../src/pages/docs/v2/07-governance/03-guide-to-voting.md" /* webpackChunkName: "component---src-pages-docs-v-2-07-governance-03-guide-to-voting-md" */),
  "component---src-pages-docs-v-2-07-governance-04-glossary-md": () => import("./../src/pages/docs/v2/07-governance/04-glossary.md" /* webpackChunkName: "component---src-pages-docs-v-2-07-governance-04-glossary-md" */),
  "component---src-pages-docs-v-2-07-governance-05-adversarial-circumstances-md": () => import("./../src/pages/docs/v2/07-governance/05-adversarial-circumstances.md" /* webpackChunkName: "component---src-pages-docs-v-2-07-governance-05-adversarial-circumstances-md" */),
  "component---src-pages-docs-v-2-07-governance-05-governance-reference-md": () => import("./../src/pages/docs/v2/07-governance/05-governance-reference.md" /* webpackChunkName: "component---src-pages-docs-v-2-07-governance-05-governance-reference-md" */),
  "component---src-pages-docs-v-2-07-governance-index-js": () => import("./../src/pages/docs/v2/07-governance/index.js" /* webpackChunkName: "component---src-pages-docs-v-2-07-governance-index-js" */),
  "component---src-pages-docs-v-2-index-md": () => import("./../src/pages/docs/v2/index.md" /* webpackChunkName: "component---src-pages-docs-v-2-index-md" */),
  "component---src-pages-faq-index-md": () => import("./../src/pages/faq/index.md" /* webpackChunkName: "component---src-pages-faq-index-md" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

